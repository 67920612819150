.section {
    position: relative;
    padding-top: 100rem;
    padding-bottom: 100rem;
    z-index: 1;

    @media (max-width: @screen-m) {
        padding-top: 50rem;
        padding-bottom: 50rem;
    }
}

.section-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #F7BDC0;
    border-bottom: 1rem solid currentColor;

    i {
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        background-repeat: no-repeat;
        background-position: 0 0;
        box-sizing: border-box;
        // background-size: 100% 100%;

        &._rect {
            width: 13rem;
            height: 13rem;
            border: 1rem solid currentColor;
            background: #fff;
            margin-top: -6rem;
        }

        &._circle {
            width: 13rem;
            height: 13rem;
            border: 1rem solid currentColor;
            background: #fff;
            border-radius: 50%;
            margin-top: -6rem;
        }

        &._diamond {
            width: 13rem;
            height: 13rem;
            border: 1rem solid currentColor;
            background: #fff;
            transform: rotate(45deg);
            margin-top: -6rem;
        }

        &._triangle {
            width: 18rem;
            height: 15rem;
            margin-top: -5rem;
            background-size: 100% auto;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.87939 0.747147L9.43976 12.7207L17 0.747147H1.87939Z' fill='white' stroke='%23F7BDC0'/%3E%3C/svg%3E%0A");
        }
    }
}

.section-title {
    font-family: var(--font-sora);
    font-style: normal;
    font-weight: 400;
    font-size: 40rem;
    line-height: 150%;
    margin: 0;
    color: #000;

    @media (max-width: @screen-m) {
        text-align: center;
        font-size: 30rem;
    }
}

.section-subtitle {
    font-family: var(--font-sora);
    font-style: normal;
    font-weight: 800;
    font-size: 15rem;
    line-height: 130%;
    text-transform: uppercase;
    margin: 0 0 10rem 0;
    color: #000;

    @media (max-width: @screen-m) {
        text-align: center;
    }

    + p {
        margin-top: 0;
    }
}

.large-text {
    font-family: var(--font-sora);
    font-weight: 600;
    font-size: 20rem;
    line-height: 180%;
    max-width: 66%;
    margin: 20rem 0;
    color: #000;

    @media (max-width: @screen-m) {
        max-width: 100%;
        font-size: 16rem;
    }

    a {
        color: var(--color-red);
        text-decoration: underline;
        text-decoration-color: transparent;

        html.desktop &:hover {
            text-decoration-color: currentColor;
        }
    }
}
