.section.features {

    .section-border {

        i:nth-child(1) {
            margin-left: -48%;

            @media (max-width: @screen-m) {
                margin-left: -41%;
            }

            @media (max-width: @screen-s) {
                margin-left: -36%;
            }
        }

        i:nth-child(2) {
            margin-left: -26%;

            @media (max-width: @screen-m) {
                margin-left: -15%;
            }

            @media (max-width: @screen-s) {
                margin-left: 4%;
            }
        }

        i:nth-child(3) {
            margin-left: -17%;

            @media (max-width: @screen-m) {
                margin-left: 19%;
            }

            @media (max-width: @screen-s) {
                margin-left: 22%;
            }
        }

        i:nth-child(4) {
            margin-left: -1%;

            @media (max-width: @screen-m) {
                display: none;
            }
        }

        i:nth-child(5) {
            margin-left: 28%;

            @media (max-width: @screen-m) {
                display: none;
            }
        }

        i:nth-child(6) {
            margin-left: 33%;

            @media (max-width: @screen-m) {
                margin-left: 38%;
            }

            @media (max-width: @screen-s) {
                margin-left: 39%;
            }
        }
    }
}
