:root {
    scroll-behavior: smooth;
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    font-family: var(--font-inter);
    font-size: 1px;
    line-height: 1.3;
    font-weight: 400;
    height: 100%;
    min-height: 320px;
    min-width: 320px;
    color: #000;
    background: #fff;

    &:not(.mobile) {
        // .scrollbar();
    }

    &._has-no-visible-focus {

        * {
            outline: 0 !important;
        }
    }

    @media (min-width: @screen-xl) {
        font-size: 0.052vw;
    }
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    font-size: 14rem;
    color: var(--color-text);
}


::selection {
    color: #fff;
    background: #000;
}



// Placeholders
::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}



// :disabled
:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
    user-select: none;
}


// Simple tags
img {
    margin: 0;
}

a {
    cursor: pointer;
    transition: all 0.3s ease;
    color: var(--color-red);
    text-decoration: underline;
    text-decoration-color: transparent;

    &[href^="mailto"],
    &[href^="tel"] {
        user-select: text;
    }

    html.desktop &:hover {
        color: var(--color-hover);
        text-decoration-color: currentColor;
    }
}

ul {

    nav & {
        margin: 0;
        padding: 0;
    }
}

button,
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
}

textarea,
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"] {
    -webkit-appearance: none;
}

textarea {

    html.desktop & {
        .scrollbar();
    }
}

select {
    // appearance: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

figure,
figcaption {
    margin: 0;
    padding: 0;
}

template {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

p {
    margin: 1em 0;
}

.content a[target="_blank"]:not([class]) {
    // background: yellow !important;
    position: relative;
    display: inline-block;
    margin-right: 0.1em;

    &:after {
        content: '';
        display: inline-block;
        vertical-align: baseline;
        width: 1em;
        height: 0.9em;
        position: relative;
        left: 0.15em;
        top: 0.1em;
        background-image: url("data:image/svg+xml,%3Csvg width='62' height='61' viewBox='0 0 62 61' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M53.531 5.71875L38.281 5.71875C37.2282 5.71875 36.3747 6.57221 36.3747 7.625C36.3747 8.6778 37.2282 9.53125 38.281 9.53125L48.9289 9.53125L24.2247 34.2354C23.4803 34.9798 23.4803 36.1868 24.2248 36.9312C24.9692 37.6757 26.1762 37.6757 26.9206 36.9312L51.6247 12.2271L51.6248 22.875C51.6248 23.9278 52.4782 24.7812 53.531 24.7812C54.5838 24.7812 55.4373 23.9278 55.4373 22.875L55.4372 7.625C55.4372 6.57221 54.5838 5.71875 53.531 5.71875ZM17.9477 10.8021C11.28 10.8021 5.87476 16.2073 5.87476 22.875V43.2083C5.87476 49.876 11.28 55.2812 17.9477 55.2812H38.281C44.9487 55.2812 50.3539 49.876 50.3539 43.2083V30.5C50.3539 29.4472 49.5005 28.5938 48.4477 28.5938C47.3949 28.5938 46.5414 29.4472 46.5414 30.5V43.2083C46.5414 47.7704 42.8431 51.4688 38.281 51.4688H17.9477C13.3856 51.4688 9.68726 47.7704 9.68726 43.2083V22.875C9.68726 18.3129 13.3856 14.6146 17.9477 14.6146H30.656C31.7088 14.6146 32.5623 13.7611 32.5623 12.7083C32.5623 11.6555 31.7088 10.8021 30.656 10.8021H17.9477Z' fill='%23E4232D'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
}
