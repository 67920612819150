.linear__inner {
    display: grid;
    column-gap: 3%;
    position: relative;

    @media (max-width: @screen-m) {
        column-gap: 4vw;
    }

    @media (max-width: @screen-s) {
        grid-template-columns: 1fr;
    }
}

.linear--4 {

    .linear__inner {
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: @screen-m) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: @screen-s) {
            grid-template-columns: 1fr;
            row-gap: 80rem;
        }
    }
}

.linear--3 {

    .linear__inner {
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: @screen-m) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: @screen-s) {
            grid-template-columns: 1fr;
        }
    }
}

.linear--2 {

    .linear__inner {
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: @screen-s) {
            grid-template-columns: 1fr;
        }
    }
}

.linear--icons-big {

    .linear__inner {
        row-gap: 80rem;
    }
}

.linear--icons-small {

    .linear__inner {
        row-gap: 50rem;

        @media (max-width: @screen-s) {
            row-gap: 60rem;
        }
    }
}

.linear-item {
    position: relative;

    .linear--icons-big & {
        padding-top: 87rem;

        @media (max-width: @screen-m) {
            padding-top: 62rem;
        }
    }

    .linear--icons-small & {
        padding-top: 42rem;

        @media (max-width: @screen-m) {
            padding-top: 42rem;
        }
    }

    i {
        color: #000;
    }

    a {
        color: var(--color-red);
        text-decoration: underline;
        text-decoration-color: transparent;

        html.desktop &:hover {
            text-decoration-color: currentColor;
        }
    }
}

.linear-item__icon {
    position: absolute;
    left: 0;
    top: 2rem;
    width: 0;
    height: 0;
    color: #fff;
    z-index: 2;
    // opacity: 0;
    transition: color 0.4s ease-out,
                opacity 0.35s ease-out;

    @media (max-width: @screen-m) {
        left: 50%;
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);

        @media (max-width: @screen-m) {
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .linear-item._is-active & {
        color: var(--color-red);
    }
}

.linear-item__content {
    line-height: 1.8;
    opacity: 0;
    transition: opacity 0.5s ease-out;

    @media (max-width: @screen-m) {
        // max-width: 280rem;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: @screen-s) {
        // min-height: 90rem;
        max-width: 360rem;
    }

    p {
        margin: 2em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.linear--icons-big {
    margin-top: 80rem;

    @media (max-width: @screen-m) {
        // margin-top: 70rem;
    }
}

.linear--icons-small {
    margin-top: 70rem;

    @media (max-width: @screen-m) {
        margin-top: 50rem;
    }
}


.line__wrap {
    height: 100%;
    .wrap();
    background: green;
}


.linear-item__line {
    height: 4rem;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    .linear--icons-small & {
        height: 1rem;
        top: 2rem;
    }
}

.linear-item__line-inner-1 {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--color-red);
    // opacity: 0.5;
    transform-origin: left;
    transform: scaleX(0);
    will-change: transform;
    transition: transform 0.4s cubic-bezier(.78, 0, .28, 1);
}

.linear-item__line-inner-2 {
    position: absolute;
    height: 100%;
    top: 0;
    background: var(--color-red);
    // opacity: 0.5;
    transform-origin: left;
    transform: scaleX(0);
    will-change: transform;
    transition: transform 0.3s cubic-bezier(.78, 0, .28, 1);
}



// ---- 4 ----
.linear--4 {

    // 4
    @media (min-width: (@screen-m + 1)) {

        .linear-item__line-inner-1 {
            right: calc(-14% - 45rem);
            margin-left: 35rem;
        }

        .linear-item:nth-child(4n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 43vw) * -1);
            }
        }

        .linear-item:nth-child(4n + 1) {
            .linear-item__line-inner-2 {
                left: calc((50vw - 43vw) * -1);
                right: calc(100% - 35rem);
            }
        }


        .linear-item:nth-child(4n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -75vw;
                transition-duration: 1.6s;
            }
        }

        .linear-item:nth-child(4n + 2):last-child {
            .linear-item__line-inner-1 {
                right: -52vw;
                transition-duration: 1.3s;
            }
        }

        .linear-item:nth-child(4n + 3):last-child {
            .linear-item__line-inner-1 {
                right: -30vw;
                transition-duration: 1s;
            }
        }
    }

    @media (min-width: 1380px) {

        .linear-item:nth-child(4n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 600rem) * -1);
            }
        }

        .linear-item:nth-child(4n + 1) {
            .linear-item__line-inner-2 {
                // 1200px / 86vw * 100
                left: calc((50vw - 600rem) * -1);
            }
        }

        .linear-item:nth-child(4n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -68vw;
            }
        }

        .linear-item:nth-child(4n + 3):last-child {
            .linear-item__line-inner-1 {
                right: -36vw;
            }
        }
    }

    // 2
    @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
        .linear-item:nth-child(2n + 1) {

            .linear-item__line-inner-1 {
                right: -26vw;
                left: 50%;
            }

            .linear-item__line-inner-2 {
                display: block;
                left: -4vw;
                right: 50%;
                transition-duration: 0.3s;
            }
        }

        .linear-item:nth-child(2n) {
            .linear-item__line-inner-1 {
                right: -4vw;
                left: 50%;
                transition-duration: 0.3s;
            }
        }

        .linear-item:nth-child(2n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -53vw;
                transition-duration: 1s;
            }
        }
    }

    // 1
    @media (max-width: @screen-s) {
        .linear-item__line-inner-1 {
            left: 50%;
            right: -7vw;
        }

        .linear-item__line-inner-2 {
            right: 50%;
            left: -50vw;
        }
    }
}


// ---- 3 ----
.linear--3 {

    // 3
    @media (min-width: (@screen-m + 1)) {

        .linear-item__line-inner-1 {
            right: calc(-14% - 9rem);
            margin-left: 20rem;
        }

        .linear-item:nth-child(3n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 43vw) * -1);
            }
        }

        .linear-item:nth-child(3n + 1) {
            .linear-item__line-inner-2 {
                left: calc((50vw - 43vw) * -1);
                right: calc(100% - 20rem);
            }
        }

        .linear-item:nth-child(3n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -67vw;
                transition-duration: 1.5s;
            }
        }

        .linear-item:nth-child(3n + 2):last-child {
            .linear-item__line-inner-1 {
                right: -37vw;
                transition-duration: 0.9s;
            }
        }
    }

    @media (min-width: 1380px) {

        .linear-item:nth-child(3n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 600rem) * -1);
            }
        }

        .linear-item:nth-child(3n + 1) {
            .linear-item__line-inner-2 {
                // 1200px / 86vw * 100
                left: calc((50vw - 600rem) * -1);
            }
        }

        .linear-item:nth-child(3n + 2):last-child {
            .linear-item__line-inner-1 {
                right: calc((50vw - 180rem) * -1);
            }
        }
    }

    // 2
    @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
        .linear-item:nth-child(2n + 1) {

            .linear-item__line-inner-1 {
                right: -26vw;
                left: 50%;
            }

            .linear-item__line-inner-2 {
                display: block;
                left: -4vw;
                right: 50%;
                transition-duration: 0.3s;
            }
        }

        .linear-item:nth-child(2n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -53vw;
                transition-duration: 0.8s;
            }
        }

        .linear-item:nth-child(2n) {
            .linear-item__line-inner-1 {
                right: -4vw;
                left: 50%;
                transition-duration: 0.3s;
            }
        }
    }

    // 1
    @media (max-width: @screen-s) {
        .linear-item__line-inner-1 {
            left: 50%;
            right: -7vw;
        }

        .linear-item__line-inner-2 {
            right: 50%;
            left: -50vw;
        }
    }
}


// ---- 2 ----
.linear--2 {
    // 2
    @media (min-width: (@screen-m + 1)) {

        .linear-item__line-inner-1 {
            right: -14%;
            margin-left: 35rem;
        }

        .linear-item:nth-child(2n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 43vw) * -1);
            }
        }

        .linear-item:nth-child(2n + 1) {
            .linear-item__line-inner-2 {
                left: calc((50vw - 43vw) * -1);
                right: calc(100% - 35rem);
            }
        }

        .linear-item:nth-child(2n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -49vw;
            }
        }
    }

    @media (min-width: 1380px) {

        .linear-item:nth-child(2n) {
            .linear-item__line-inner-1 {
                right: calc((50vw - 590rem) * -1);
            }
        }

        .linear-item:nth-child(2n + 1) {
            .linear-item__line-inner-2 {
                // 1200px / 86vw * 100
                left: calc((50vw - 590rem) * -1);
            }
        }

        .linear-item:nth-child(2n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -52vw;
            }
        }
    }

    // 1
    @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
        .linear-item:nth-child(2n + 1) {

            .linear-item__line-inner-1 {
                right: -26vw;
                left: 50%;
            }

            .linear-item__line-inner-2 {
                display: block;
                left: -4vw;
                right: 50%;
                transition-duration: 0.3s;
            }
        }

        .linear-item:nth-child(2n + 1):last-child {
            .linear-item__line-inner-1 {
                right: -53vw;
            }
        }

        .linear-item:nth-child(2n) {
            .linear-item__line-inner-1 {
                right: -4vw;
                left: 50%;
                transition-duration: 0.3s;
            }
        }
    }

    // 1
    @media (max-width: @screen-s) {
        .linear-item__line-inner-1 {
            left: 50%;
            right: -7vw;
        }

        .linear-item__line-inner-2 {
            right: 50%;
            left: -50vw;
        }
    }
}

// ---- Sumeragi ----
.sumeragi .linear--3 {
    @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
        .linear-item__line-inner-1 {
            left: 50%;
            right: -4vw !important;
        }

        .linear-item__line-inner-2 {
            right: 50%;
            left: -4vw !important;
        }
    }
}



.linear-item._is-active {

    .linear-item__line-inner-1,
    .linear-item__line-inner-2 {
        transform: scaleX(1);
    }

    .linear-item__icon,
    .linear-item__content {
        opacity: 1;
    }

    .linear--4 & {

        @media (min-width: (@screen-m + 1)) {
            &:nth-child(4n + 1) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(4n + 2) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }

            &:nth-child(4n + 3) {
                .linear-item__line-inner-1 {
                    transition-delay: 1.1s;
                }

                .linear-item__icon {
                    transition-delay: 1s;
                }

                .linear-item__content {
                    transition-delay: 1.1s;
                }
            }

            &:nth-child(4n + 4) {
                .linear-item__line-inner-1 {
                    transition-delay: 1.5s;
                }

                .linear-item__icon {
                    transition-delay: 1.4s;
                }

                .linear-item__content {
                    transition-delay: 1.5s;
                }
            }
        }

        @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
            &:nth-child(2n + 1) {

                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(2n) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }
        }

        @media (max-width: @screen-s) {
            .linear-item__line-inner-1 {
                transition-delay: 0.4s;
            }

            .linear-item__icon {
                transition-delay: 0.3s;
            }

            .linear-item__content {
                transition-delay: 0.4s;
            }
        }
    }

    .linear--3 & {

        @media (min-width: (@screen-m + 1)) {
            &:nth-child(3n + 1) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(3n + 2) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }

            &:nth-child(3n + 3) {
                .linear-item__line-inner-1 {
                    transition-delay: 1.1s;
                }

                .linear-item__icon {
                    transition-delay: 1s;
                }

                .linear-item__content {
                    transition-delay: 1.1s;
                }
            }
        }

        @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
            &:nth-child(2n + 1) {

                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(2n) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }
        }

        @media (max-width: @screen-s) {
            .linear-item__line-inner-1 {
                transition-delay: 0.4s;
            }

            .linear-item__icon {
                transition-delay: 0.3s;
            }

            .linear-item__content {
                transition-delay: 0.4s;
            }
        }
    }

    .linear--2 & {

        @media (min-width: (@screen-m + 1)) {
            &:nth-child(2n + 1) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(2n) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }
        }

        @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
            &:nth-child(2n + 1) {

                .linear-item__line-inner-1 {
                    transition-delay: 0.3s;
                }

                .linear-item__icon {
                    transition-delay: 0.2s;
                }

                .linear-item__content {
                    transition-delay: 0.3s;
                }
            }

            &:nth-child(2n) {
                .linear-item__line-inner-1 {
                    transition-delay: 0.7s;
                }

                .linear-item__icon {
                    transition-delay: 0.6s;
                }

                .linear-item__content {
                    transition-delay: 0.7s;
                }
            }
        }

        @media (max-width: @screen-s) {
            .linear-item__line-inner-1 {
                transition-delay: 0.4s;
            }

            .linear-item__icon {
                transition-delay: 0.3s;
            }

            .linear-item__content {
                transition-delay: 0.4s;
            }
        }
    }

    .sumeragi .linear--3 & {
        @media (max-width: @screen-m) and (min-width: (@screen-s + 1)) {
            .linear-item__line-inner-1 {
                transition-delay: 0.4s;
            }

            .linear-item__icon {
                transition-delay: 0.3s;
            }

            .linear-item__content {
                transition-delay: 0.4s;
            }
        }
    }
}
