.section.hero {
    padding-top: 52rem;
    position: relative;
    z-index: 0;
    padding-bottom: 310rem;
    margin-bottom: -230rem;
    overflow: hidden;

    @media (max-width: @screen-m) {
        padding-top: 30rem;
        padding-bottom: 300rem;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200rem;
        z-index: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
        // background: #eee;
    }

    .section-border {
        display: none;

        i:nth-child(1) {
            margin-left: -40%;

            @media (max-width: @screen-m) {
                margin-left: -41%;
            }

            @media (max-width: @screen-s) {
                margin-left: -36%;
            }
        }

        i:nth-child(2) {
            margin-left: -34%;

            @media (max-width: @screen-m) {
                margin-left: -15%;
            }

            @media (max-width: @screen-s) {
                margin-left: 4%;
            }
        }

        i:nth-child(3) {
            margin-left: -6%;

            @media (max-width: @screen-m) {
                margin-left: 19%;
            }

            @media (max-width: @screen-s) {
                margin-left: 22%;
            }
        }

        i:nth-child(4) {
            margin-left: 11%;

            @media (max-width: @screen-m) {
                display: none;
            }
        }

        i:nth-child(5) {
            margin-left: 20%;

            @media (max-width: @screen-m) {
                display: none;
            }
        }

        i:nth-child(6) {
            margin-left: 42%;

            @media (max-width: @screen-m) {
                margin-left: 38%;
            }

            @media (max-width: @screen-s) {
                margin-left: 39%;
            }
        }
    }

    .section__inner {
        display: flex;

        @media (max-width: @screen-m) {
            flex-direction: column-reverse;
        }
    }

    .webgl {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
}

.hero-text {
    width: 50%;
    box-sizing: border-box;
    padding-right: 60rem;
    position: relative;
    z-index: 2;

    @media (max-width: @screen-l) {
        padding-right: 20rem;
    }

    @media (max-width: @screen-m) {
        width: 100%;
        padding-right: 0;
    }

    h1 {
        display: block;
        font-family: 'Sora';
        font-weight: 700;
        font-size: 70rem;
        line-height: 1.2;
        color: #000;
        position: relative;
        margin: 50rem 0 60rem 0;
        letter-spacing: -0.01em;
        white-space: nowrap;

        @media (max-width: @screen-l) {
            font-size: 60rem;
            margin: 10rem 0 40rem 0;
        }

        @media (max-width: @screen-m) {
            white-space: normal;
        }

        @media (max-width: @screen-s) {
            font-size: 40rem;
            margin: 0 0 20rem 0;
        }

        span {
            // display: block;
            color: var(--color-red);
            white-space: nowrap;

            @media (max-width: @screen-m) {
                display: inline-block;
            }
        }

        + p {
            margin-top: 0;
        }
    }

    p {
        margin: 1.7em 0;
        font-family: 'Sora';
        font-size: 18rem;
        line-height: 180%;
        letter-spacing: -0.01em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: @screen-m) {
            font-size: 16rem;
        }
    }

    a {
        color: var(--color-red);
        text-decoration: underline;
        text-decoration-color: transparent;

        html.desktop &:hover {
            text-decoration-color: currentColor;
        }
    }
}

.hero-logo {
    width: 50%;
    position: relative;
    z-index: 0;
    top: 20rem;
    left: 80rem;
    transform: translateZ(0);

    @media (max-width: 1580px) {
        left: 20rem;
    }

    @media (max-width: 1350px) {
        left: 0;
    }

    @media (max-width: @screen-l) {
        left: 30rem;
    }

    @media (max-width: @screen-m) {
        width: 100%;
        top: 0;
        left: 0;
    }
}

.hero-logo__svg {
    width: 540rem;
    height: 540rem;
    position: relative;
    top: 70rem;
    left: 20rem;

    @media (max-width: @screen-l) {
        width: 454rem;
        height: 454rem;
        left: -10rem;
    }

    @media (max-width: @screen-m) {
        width: 350rem;
        height: 350rem;
        top: -10rem;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: @screen-s) {
        width: 210rem;
        height: 210rem;
        top: -20rem;
    }
}

.hero-logo__pattern {
    width: 2842rem;
    height: 3253rem;
    position: absolute;
    left: -1129rem;
    top: -1295rem;
    transform: rotate(15deg);
    z-index: 0;
    overflow: hidden;

    ._out {
        position: absolute;
        width: 2842rem;
        height: 3253rem;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        animation: spinRight 280s linear infinite;
        z-index: 0;
        background: url('/src/img/out.svg') no-repeat 0 0;
        background-repeat: no-repeat;
    }

    ._middle {
        position: absolute;
        width: 1424rem;
        height: 1623rem;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        animation: spinLeft 240s linear infinite;
        z-index: 1;
        background: url('/src/img/middle.svg') no-repeat 0 0;
        background-repeat: no-repeat;
    }

    ._in {
        position: absolute;
        width: 835rem;
        height: 960rem;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        animation: spinRight 180s linear infinite;
        z-index: 2;
        background: url('/src/img/in.svg') no-repeat 0 0;
        background-repeat: no-repeat;
    }

    @media (max-width: @screen-l) {
        transform: rotate(15deg) scale(0.84);
        left: -1203rem;
        top: -1336rem;
    }

    @media (max-width: @screen-m) {
        left: 50%;
        top: -1467rem;
        margin-left: -1420rem;
        transform: rotate(15deg) scale(0.65);
    }

    @media (max-width: @screen-s) {
        top: -1547rem;
        margin-left: -1420rem;
        transform: rotate(15deg) scale(0.38);
    }
}

@keyframes spinRight {
    0% { transform: translateX(-50%) translateY(-50%) rotate(0deg) }
    100% { transform: translateX(-50%) translateY(-50%) rotate(360deg) }
}

@keyframes spinLeft {
    0% { transform: translateX(-50%) translateY(-50%) rotate(0deg) }
    100% { transform: translateX(-50%) translateY(-50%) rotate(-360deg) }
}
