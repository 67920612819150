// Default outline for :focus state
.focused() {
    // outline: 1px dotted #212121;
    outline: 1px dotted currentColor;
    outline: 5px auto -webkit-focus-ring-color;
}


// Customized native scrollbar
.scrollbar() {
    // Firefox
    scrollbar-width: thin;
    scrollbar-color: #fff #000;

    // IE
    // -ms-overflow-style: -ms-autohiding-scrollbar;

    // Webkit
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background: #000;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #fff;
    }
}


// Invisible, but available for keybord and screen readers
.v-hidden() {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    clip: rect(0 0 0 0);
}
