.wrap {
    width: 86vw;
    max-width: 1200rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: @screen-l) {
        width: 86vw;
    }

    @media (max-width: @screen-m) {
        width: 92vw;
    }

    @media (max-width: @screen-s) {
        width: auto;
        margin-left: 16rem;
        margin-right: 16rem;
    }
}

.wrap-page {
    overflow: hidden;
}

.content {
    padding-top: 50rem;
    position: relative;
    z-index: 3;
}
