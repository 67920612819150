body {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
}


html._is-preloaded {

    body {
        pointer-events: auto;
        opacity: 1;
    }
}
