.partners {
    padding-top: 140rem;

    @media (max-width: @screen-m) {
        padding-top: 100rem;
    }

    .section__inner {
        position: relative;
        text-align: center;
    }

    .section-title {
        text-align: left;

        @media (max-width: @screen-m) {
            text-align: center;
        }
    }
}

.partners__become-btn {
    position: absolute;
    right: 0;
    top: 14rem;

    @media (max-width: @screen-m) {
        position: relative;
        top: unset;
        margin-top: 20rem;
    }
}

.partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50rem -25rem 0 -25rem;
    padding: 0;
    list-style: none;

    @media (max-width: @screen-l) {
        margin: 50rem -20rem 0 -20rem;
    }

    @media (max-width: @screen-m) {
        margin: 50rem -25rem 0 -25rem;
    }

    @media (max-width: @screen-s) {
        margin: 50rem -15rem 0 -15rem;
    }
}

.partners-list__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    box-sizing: border-box;
    padding: 25rem 25rem;

    @media (max-width: @screen-l) {
        padding: 25rem 20rem;
    }

    @media (max-width: @screen-m) {
        width: 33.333%;
        padding: 20rem 25rem;
    }

    @media (max-width: @screen-s) {
        padding: 15rem 15rem;
    }
}

.partners-list__item-img {
    width: 160rem;
    height: 100%;
    max-width: 100%;
    display: block;
    position: relative;
    overflow: hidden;

    @media (max-width: @screen-s) {
        width: 120px;
    }

    html.desktop &:hover img {
        // filter: grayscale(0);
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        // filter: grayscale(1);
        transition: all 0.2s ease;
    }

    span {
        .v-hidden();
    }
}
