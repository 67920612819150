.section.rust {
    margin-top: 30rem;

    .section-border {
        &:extend(.section.hero .section-border all);
    }
}

.rust-line {
    height: 0;
    position: relative;
    margin: 38rem 0 41rem 0;

    @media (max-width: @screen-m) {
        margin: 120rem 0 115rem 0;
    }
}

.rust-line__line {
    position: absolute;
    width: 100vw;
    left: 50%;
    height: 4rem;
    margin-left: -50vw;
    background: var(--color-red);
}

.rust-linear__inner {
    display: grid;
    height: 100%;
    column-gap: 3%;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: @screen-m) {
        grid-template-columns: 1fr;
    }
}

.rust-linear__logo {
    grid-column-start: 3;
    width: 271rem;
    height: 271rem;
    position: relative;
    color: var(--color-red);
    transition: color 0.4s ease-out;
    margin-top: -7rem;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: -50%;
        background: #fff;
        border-radius: 50%;
        margin-top: 2rem;
        transform: scale(0.85);
    }

    @media (max-width: @screen-m) {
        grid-column-start: unset;
        left: 50%;
        width: 188rem;
        height: 188rem;
        margin-left: -94rem;
        margin-top: -5rem;
    }

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: -50%;
        margin-top: 2rem;
        z-index: 2;

        @media (max-width: @screen-m) {
            path {
                stroke-width: 5;
            }
        }
    }
}
