.contribute {
    text-align: center;
    padding-bottom: 0;

    .large-text {
        margin-left: auto;
        margin-right: auto;
    }
}

.contribute__btn-container {
    margin-top: 2em;
}