/*
 * https://google-webfonts-helper.herokuapp.com/fonts/sora?subsets=latin
 */

// SORA

/* sora-100 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/sora/sora-v10-latin-100.woff2') format('woff2');
}
/* sora-300 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/sora/sora-v10-latin-300.woff2') format('woff2');
}
/* sora-200 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/sora/sora-v10-latin-200.woff2') format('woff2');
}
/* sora-regular - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/sora/sora-v10-latin-regular.woff2') format('woff2');
}
/* sora-500 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/sora/sora-v10-latin-500.woff2') format('woff2');
}
/* sora-700 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/sora/sora-v10-latin-700.woff2') format('woff2');
}
/* sora-600 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/sora/sora-v10-latin-600.woff2') format('woff2');
}
/* sora-800 - latin */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/sora/sora-v10-latin-800.woff2') format('woff2');
}


// INTER
/* inter-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter/inter-v12-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2');
}

/* inter-900 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/inter/inter-v12-latin-ext_latin_cyrillic-ext_cyrillic-900.woff2') format('woff2');
}
