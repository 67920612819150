.footer {
    margin-top: 160rem;
    background: var(--color-red);
    color: #fff;

    @media (max-width: @screen-s) {
        margin-top: 130rem;
    }
}

.footer__content {
    min-height: 48rem;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    text-transform: uppercase;
    box-sizing: border-box;

    @media (max-width: @screen-m) {
        flex-direction: column;
        padding: 7rem 0;
    }

    a {
        user-select: none;

        span {
            display: block;
            line-height: 28rem;
            color: #fff;
            position: relative;
            font-weight: 900;
            font-size: 12px;
            line-height: 28px;
            text-transform: uppercase;

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 4rem;
                height: 2rem;
                width: 100%;
                background: #fff;
                opacity: 0;
                transition: opacity 0.2s ease;
            }

            html.desktop &:hover:after {
                opacity: 1;
            }
        }
    }
}

a.footer__copyright {
    position: absolute;
    left: 0;

    @media (max-width: @screen-m) {
        position: relative;
        margin-bottom: -5rem;
    }
}

a.footer__top-link {
    position: absolute;
    right: 0;
    border: 0;
    background: none;
    padding: 0 18rem 0 0;
    white-space: nowrap;

    @media (max-width: @screen-m) {
        padding-right: 40rem;
        height: 40rem;
        right: -15rem;
    }

    span {
        @media (max-width: @screen-m) {
            .v-hidden();
        }
    }

    svg {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -9rem;

        @media (max-width: @screen-m) {
            right: 15rem;
        }
    }
}
