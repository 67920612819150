.get-started {
    padding-bottom: 0;
}

.get-started__steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3%;
    list-style: none;
    margin: 60rem 0 0 0;
    padding: 0;

    li {
        display: block;
    }

    @media (max-width: @screen-m) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 4vw;
        row-gap: 40rem;
    }

    @media (max-width: @screen-s) {
        grid-template-columns: 1fr 1fr;
        column-gap: 0;
    }
}

.get-started__step-link {
    display: block;
    transition: all 0.3s ease;

    html.desktop &:hover {

        svg {
            color: #000;
        }
    }

    svg {
        width: 61rem;
        height: 61rem;
        color: var(--color-red);
        margin-bottom: 14rem;
        transition: inherit;
        position: relative;
        margin-left: -4rem;

        @media (max-width: @screen-m) {
            left: 50%;
            margin-left: -30rem;
        }
    }
}

.get-started__step-text {
    font-size: 16px;
    line-height: 1.6;
    transition: inherit;

    @media (max-width: @screen-m) {
        text-align: center;
    }

    html.desktop .get-started__step-link:hover & {

        span {
            text-decoration-color: currentColor;
        }
    }

    span {
        color: var(--color-red);
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: inherit;
    }
}
