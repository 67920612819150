// CSS varibles
:root {
    --font-inter: 'Inter', sans-serif;
    --font-sora: 'Sora', sans-serif;

    --color-red: #E4232D;
    --color-hover: #F4343E;
    --color-text: #4A4A4A;
    --color-black: #000;
}

// Breakpoints
@screen-xs: 359px;
@screen-s: 559px;
@screen-m: 959px;
@screen-l: 1199px;
@screen-xl: 1921px;

@max-width: 1200rem;
