.section.additional {
    padding: 0;

    .section__inner {
        position: relative;
        padding: 60rem 0 0 0;
        box-sizing: border-box;

        @media (max-width: @screen-m) {
            padding-top: 20rem;
            // padding-bottom: 20rem;
        }

        &:before {
            content: '';
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            border: 4rem solid var(--color-red);
            box-sizing: border-box;
            background: #fff;
            z-index: 0;
        }
    }

    .section-title {
        margin: 0 5%;
        position: relative;
        z-index: 2;

        @media (max-width: @screen-m) {
            margin: 0 4%;
            text-align: left;
        }
    }

    .columns {
        margin: 60rem 5% 0 5%;
        position: relative;
        z-index: 2;

        @media (max-width: @screen-m) {
            margin: 20rem 4% 0 4%;
        }
    }
}

.columns--2 {

    .columns__inner {
        display: grid;
        column-gap: 3%;
        grid-template-columns: 1fr 1fr;

        @media (max-width: @screen-m) {
            // display: block;
            grid-template-columns: 1fr;
        }
    }
}

.columns-item {
    position: relative;
    padding-left: 97rem;
    margin-bottom: 80rem;

    @media (max-width: @screen-l) {
        margin-bottom: 60rem;
    }

    @media (max-width: @screen-m) {
        padding-left: 0;
        margin-bottom: 40rem;
    }

    p {
        margin: 2em 0;
        line-height: 1.8;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.columns-item__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 70rem;
    height: 70rem;

    @media (max-width: @screen-m) {
        position: relative;
        margin: 0 auto 24rem auto;
        cursor: pointer;
    }
}

.columns-item.details {

    .section-subtitle {
        pointer-events: none;

        @media (max-width: @screen-m) {
            cursor: pointer;
            position: relative;
            padding-right: 10rem;
            pointer-events: auto;
            user-select: none;
            transition: all 0.3s ease;

            &:after {
                content: '';
                display: inline-block;
                width: 12rem;
                height: 7rem;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 -5.24537e-07L12 7L0 7L6 -5.24537e-07Z' fill='%23E4232D'/%3E%3C/svg%3E%0A");
                position: absolute;
                margin-left: 6rem;
                margin-top: 5rem;
                transform: rotate(180deg);
                transition: inherit;
            }
        }
    }

    &._is-open {

        .section-subtitle {

            @media (max-width: @screen-m) {
                color: var(--color-red);

                &:after {
                    transform: rotate(0deg);
                }
            }
        }

        .columns-item__text {

            @media (max-width: @screen-m) {
                opacity: 1;
            }
        }
    }
}

.columns-item__text {
    @media (max-width: @screen-m) {
        height: 0;
        opacity: 0;
        transition: opacity 0.4s ease;
    }

    @media (min-width: (@screen-m + 1)) {
        height: auto !important;
    }
}
