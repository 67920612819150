.progressbar {
    position: fixed;
    top: 47rem;
    left: 0;
    width: 101%;
    z-index: 4;
    overflow: hidden;
    height: 5rem;
}

.progressbar__inner {
    position: absolute;
    left: 0;
    height: 5rem;
    width: 0;
    background: var(--color-text);
}
