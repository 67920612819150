.section.sumeragi {

    .large-text {
        max-width: 50%;

        @media (max-width: @screen-m) {
            max-width: 100%;
        }
    }

    .section-border {
        &:extend(.section.features .section-border all);
    }

    .linear--3 {

        .linear__inner {

                @media (max-width: @screen-m) {
                    grid-template-columns: 1fr;
                }
            }
    }
}

.sumeragi-line {
    height: 0;
    position: relative;
    margin: 38rem 0 41rem 0;

    @media (max-width: @screen-m) {
        margin: 70rem 0 60rem 0;
    }

    &:before {
        content: '';
        display: block;
        width: 100vw;
        height: 4rem;
        background: var(--color-red);
        position: absolute;
        left: 50%;
        margin-left: -50vw;
    }
}

.sumeragi-linear__inner {
    display: grid;
    height: 100%;
    column-gap: 3%;
    grid-template-columns: repeat(12, 1fr);

    @media (max-width: @screen-m) {
        grid-template-columns: 1fr;
    }
}

.sumeragi-linear__logos {
    color: var(--color-red);
    grid-column-start: 8;
    grid-column-end: 13;
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 121rem;
    top: -50%;
    margin-top: 2rem;

    @media (max-width: @screen-l) {
        grid-column-start: 7;
    }

    @media (max-width: @screen-m) {
        width: 330rem;
        grid-column-start: unset;
        grid-column-end: unset;
        margin: 0 auto;
        height: 90rem;
    }
}

.sumeragi-linear__logo {
    width: 121rem;
    height: 121rem;
    position: relative;

    @media (max-width: @screen-m) {
        width: 90rem;
        height: 90rem;
    }

    &:first-child svg {
        transform: rotate(45deg);
    }

    &:last-child svg {
        transform: rotate(-135deg);
    }

    svg {
        width: 100%;
        height: 100%;
    }
}
