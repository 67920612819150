.rich {
    max-width: 900rem;
    overflow: hidden;
    font-family: var(--font-sora);
    font-size: 18rem;
    line-height: 180%;
    letter-spacing: -0.01em;

    @media (max-width: @screen-m) {
        font-size: 16rem;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: var(--font-sora);
        color: #000;
    }

    h1 {
        font-weight: 700;
        font-size: 70rem;
        line-height: 1.2;
        letter-spacing: -0.01em;

        @media (max-width: @screen-l) {
            font-size: 60rem;
        }

        @media (max-width: @screen-s) {
            font-size: 40rem;
        }
    }

    h2 {
        font-weight: 400;
        font-size: 40rem;
        line-height: 1.5;

        @media (max-width: @screen-m) {
            font-size: 30rem;
        }
    }

    h1:not(:first-child):not([class]) {
        margin-top: 1em;
    }

    h2:not(:first-child):not([class]) {
        margin-top: 1.6em;
    }

    h3:not(:first-child):not([class]) {
        margin-top: 2em;
    }

    h4:not(:first-child):not([class]),
    h5:not(:first-child):not([class]),
    h6:not(:first-child):not([class]) {
        margin-top: 2em;
    }

    p:not(:first-child):not([class]),
    ul:not([class]),
    ol:not([class]) {
        margin-top: 1.4em;
    }

    p:not(:last-child):not([class]),
    ul:not([class]),
    ol:not([class]) {
        margin-bottom: 1em;
    }

    h1:not(:last-child):not([class]) {
        margin-bottom: 0.55em;
    }

    h2:not(:last-child):not([class]),
    h3:not(:last-child):not([class]) {
        margin-bottom: 0.6em;
    }

    h4:not(:last-child):not([class]),
    h5:not(:last-child):not([class]),
    h6:not(:last-child):not([class]) {
        margin-bottom: 1em;
    }

    li:not(:last-child):not([class]) {
        margin-bottom: 0.5em;
    }

    table:not(:first-child):not([class]) {
        margin-top: 3em;
    }

    table:not(:last-child):not([class]) {
        margin-bottom: 3em;
    }

    ul:not([class]),
    ol:not([class]) {
        display: block;
        padding: 0 0 0 2.2em;
    }

    table {
        width: 100%;
        display: block;
        overflow-x: scroll;
        border-collapse: separate;
        -webkit-border-horizontal-spacing: 0;
        -webkit-border-vertical-spacing: 0;
        box-sizing: border-box;
        background-color: var(--color-light2);
        border-radius: var(--radius);
        font-size: var(--font-s);
    }

    table thead,
    table tbody {
        width: 100%;
    }

    table td,
    table th {
        padding: var(--space-s) var(--space-xs);
        overflow-wrap: normal;
        vertical-align: top;
        text-align: left;
        font-weight: normal;
    }

    table tr:not(:last-child) td {
        border-bottom: 1px solid var(--color-light3);
    }

    table td:not(:last-child),
    table th:not(:last-child) {
        border-right: 1px solid var(--color-light3);
    }

    table th {
        border-bottom: 1px solid var(--color-semi-dark);
        font-family: "Sora", sans-serif;
        font-weight: 700;
    }

    hr {
        margin: 2em 0;
    }

    hr+h1:not(:first-child),
    hr+h2:not(:first-child),
    hr+h3:not(:first-child),
    hr+h4:not(:first-child) {
        margin-top: unset;
    }

    img,
    video {
        display: block;
        margin: 3em auto;
        max-width: 100%;
    }
}